angular
.module('sc.search',
  ['ngResource', 'ui.router']
)

.config(($stateProvider) ->
  $stateProvider
#  .state('search',
#    url: '/search'
#    templateUrl: "search/search.tpl.html"
#    data:
#      pageTitle: "Search")

  .state('main.searchResults',
    url: '/search'
    templateUrl: "search/searchResults.tpl.html"
    controller: 'searchResultController'
    data:
      pageTitle: "Search")
)

.value('searchResult', {
  data: ''
})


.directive("searchPanel", ->
  restrict: "AE"
  transclude: true
  scope: true
  templateUrl: "search/panel.tpl.html"
  controller: class
    constructor: ($scope, Reducer) ->
      $scope.results =
        foo: "bar"

      @update = (results) ->
        $scope.results = _.reduce(results.documents, (result, v, documentClass) ->
          reducer = new Reducer(v.search, '_id')

          reduced = reducer.reduce(v.results)
          if reduced?
            result[documentClass] =
              documents: _.map(reduced, (item) ->
                reducer.compact(item)
              )
              search: v.search

          return result
        , {})
)

.directive("searchInput", (Search) ->
  restrict: "E"
  templateUrl: "search/input.tpl.html"
  scope: true
  require: [
    "?ngModel",
    "?^searchPanel"
  ]
  link: (scope, elem, attrs, ctrls, searchResult) ->
    ngModel = ctrls[0]
    searchPanel = ctrls[1]

    scope.filters = [
      'User'
      'Customer'
      'Company'
      'Location'
      'Draft'
      'Picks'
      'Course'
      'Module'
      'Sequence'
      'Agenda'
      'Lesson'
      'Region'
      'Measure'
      'Gig'
      'Deal'
      'Mandate'
      'Grant'
      'Template'
      'Term'
      'RevenueAccount'
      'CostUnit'
      'PointType'
      'Skill'
      'Crowd'
    ]

    scope.setFilter = (filter)->
      scope.activeFilter = filter
      search(ngModel.$modelValue)


    scope.clearFilter = ->
      scope.activeFilter = ''

    scope.clearFilter()

    search = (newValue)->
      Search.search(newValue, scope.activeFilter).then((results) ->
        scope.results = results
        searchPanel.update(results)
      , (e) ->
        console.error(e)
      )

    scope.$watch(->
      return ngModel.$modelValue

    , (newValue) ->
      # just search this value
      if newValue? and newValue != "" and newValue.length > 3

        # TODO block search as long as the last one is not finished
        search(newValue)

      else
        # empty result
        searchPanel.update({})
    )
)

.directive("typeaheadSearchParty", (Search) ->
  restrict: "E"
  templateUrl: "search/typeaheadSearch.tpl.html"
  scope: {
    onSelect: '&'
    filter: '@'
    partyType: '@'
    ngModel: '='
    create: '&'
    clear: '='
  }

  controller: ($scope, $filter) ->
    $scope.results = []

    $scope.getResultField = (result) ->
      if result
        partie = _.filter(result.parties, {'party_type': $scope.partyType})
        return  "#{result['number']} - #{$filter('fullName')(partie[0])} (#{partie[0].number})"

    search = (newValue)->
      Search.search(newValue, $scope.filter).then((results) ->
        if results.documents[$scope.filter]?
          $scope.results = results.documents[$scope.filter].results

      , (e) ->
        console.error(e)
      )

    clear = ->
      $scope.ngModel = ''

    $scope.$watch 'clear', (newValue, oldValue) ->
      if newValue != oldValue
        clear()

    $scope.save = ->
      if !$scope.results?.length > 0
        $scope.create()

    $scope.$watch(->
      return $scope.ngModel

    , (newValue) ->
      # just search this value
      if newValue? and newValue != "" and newValue.length > 3
        # TODO block search as long as the last one is not finished

        search(newValue)
      else
        # empty result
        $scope.results = []
    )
)

.directive("typeaheadSearch", (Search) ->
  restrict: "E"
  templateUrl: "search/typeaheadSearch.tpl.html"
  scope: {
    onSelect: '&'
    filter: '@'
    resultField: '@'
    resultField2: '@'
    resultFieldDate: '@'
    resultFieldTag: '@'
    resultFieldAddress: '='
    resultFieldDict: '@'
    ngModel: '='
    create: '&'
    clear: '='
  }

  controller: ($scope, $filter) ->
    $scope.results = []

    $scope.getResultField = (model) ->
      if model
        _field2 = ''
        _date = ''
        _tags = ''
        _address = ''
        _measure = ''
        if $scope.resultField2
          _field2 = model[$scope.resultField2]
        if $scope.resultFieldDate
          _date = $filter('date')(model[$scope.resultFieldDate], 'fullDate')
        if $scope.resultFieldAddress
          _address = $filter('fullAddress')(model[$scope.addresses][0])
        if $scope.resultFieldDict
          _measure = model[$scope.resultFieldDict][0].name if model[$scope.resultFieldDict]?.length
        if $scope.resultFieldTag
          _tags = for tag in model[$scope.resultFieldTag]
            _tags = tag
          _tags.join(' ')
        return model[$scope.resultField] + ' ' + _field2 + ' ' + _address  + ' ' + _date + ' ' + _measure  + ' ' + _tags

    search = (newValue)->
      Search.search(newValue, $scope.filter).then((results) ->
        if results.documents[$scope.filter]?
          $scope.results = results.documents[$scope.filter].results

      , (e) ->
        console.error(e)
      )

    clear = ->
      $scope.ngModel = ''

    $scope.$watch 'clear', (newValue, oldValue) ->
      if newValue != oldValue
        clear()

    $scope.save = ->
      if !$scope.results?.length > 0
        $scope.create()

    $scope.$watch(->
      return $scope.ngModel

    , (newValue) ->
      # just search this value
      if newValue? and newValue != "" and newValue.length > 3
        # TODO block search as long as the last one is not finished

        search(newValue)
      else
        # empty result
        $scope.results = []
    )
)


.directive("typeaheadUibSearchParty", (Search) ->
  restrict: "E"
  templateUrl: "search/typeaheadUibSearch.tpl.html"
  scope: {
    onSelect: '&'
    filter: '@'
    partyType: '@'
    ngModel: '='
    create: '&'
    clear: '='
  }

  controller: ($scope, $filter) ->
    $scope.results = []

    $scope.getResultField = (result) ->
      if result
        partie = _.filter(result.parties, {'party_type': $scope.partyType})
        return  "#{result['number']} - #{$filter('fullName')(partie[0])} (#{partie[0].number})"

    $scope.getSearchResults = (val, limit) ->
      Search.search(val,$scope.filter).then((results) ->
        return $filter('limitTo')(results.documents[$scope.filter].results, limit)
      , (e) ->
        console.error(e)
      )

    search = (newValue) ->
      $scope.getSearchResults(newValue, 6)

    clear = ->
      delete $scope.ngModel

    $scope.$watch 'clear', (newValue, oldValue) ->
      if newValue != oldValue
        clear()

    $scope.save = ->
      if !$scope.results?.length > 0
        $scope.create()
)


.directive("typeaheadUibSearch", (Search) ->
  restrict: "E"
  templateUrl: "search/typeaheadUibSearch.tpl.html"
  scope: {
    onSelect: '&'
    filter: '@'
    resultField: '@'
    resultField2: '@'
    resultFieldDate: '@'
    resultFieldTag: '@'
    resultFieldAddress: '='
    resultFieldDict: '@'
    ngModel: '='
    create: '&'
    clear: '='
    type: '@'
    value: '@'
    limit: '@'
    disabled: '='
  }

  controller: ($scope, $filter) ->
    $scope.results = []
    $scope.getResultField = (model) ->
      if model
        _field2 = ''
        _date = ''
        _tags = ''
        _address = ''
        _measure = ''
        if $scope.resultField2
          _field2 = model[$scope.resultField2]
        if $scope.resultFieldDate
          _date = $filter('date')(model[$scope.resultFieldDate], 'fullDate')
        if $scope.resultFieldAddress
          _address = $filter('fullAddress')(model[$scope.addresses][0])
        if $scope.resultFieldDict
          _measure = model[$scope.resultFieldDict][0].name if model[$scope.resultFieldDict]?.length
        if $scope.resultFieldTag
          _tags = for tag in model[$scope.resultFieldTag]
            _tags = tag
          _tags.join(' ')
        return model[$scope.resultField] + ' ' + _field2 + ' ' + _address  + ' ' + _date + ' ' + _measure  + ' ' + _tags


    $scope.getSearchResults = (val, limit) ->
      Search.search(val,$scope.filter).then((results) ->
        return $filter('limitTo')(results.documents[$scope.filter].results, limit)
      , (e) ->
        console.error(e)
      )

    search = (newValue) ->
      $scope.getSearchResults(newValue, 6)

    clear = ->
      delete $scope.ngModel

    $scope.$watch 'clear', (newValue, oldValue) ->
      if newValue != oldValue
        clear()

    $scope.save = ->
      if !$scope.results?.length > 0
        $scope.create()

)




.directive("selectSearch", (Search) ->
  restrict: "E"
  templateUrl: "search/selectSearch.tpl.html"
  scope: {
    onSelect: '&'
    filter: '='
    ngModel: '='
    create: '&'
  }

  link: (scope) ->
    scope.results = []
    #scope.activeFilter = scope.filter[0]
    scope.changeFilter = (filter) ->
      scope.activeFilter = filter
    scope.data = {}
    scope.data.items = []
    # Results to show
    scope.getResultField = (model) ->
      if model
        resultField = for field in scope.activeFilter.fields
          resultField = model[field]
        return resultField.join(' ')

    # start searching
    scope.search = (newValue)->
      if newValue? and newValue != "" and newValue.length > 3
        Search.search(newValue, 'Skill').then((results) ->
          scope.results = results.documents['Skill'].results
        , (e) ->
          console.error(e)
        )
    # Action for ngBlur - optional
    scope.save = ->
      if !scope.results?.length > 0
        scope.create()


)

# Typeahed search with filter
#
#  Use like this:
#  jade:
#  typeahead-search-filter(
#                data-ng-model="model"
#                data-filter="filter"
#                data-on-select="select(model)"
#                data-create="saveModel()"
#                )
#  controller:
#
#  $scope.select = (model) ->
#    do something
#
#  $scope.saveModel = ->
#    do something
#
#  $scope.filter = [
#        {name: 'Customer', fields: ['first_name', 'last_name']}
#        {name: 'Company', fields: ['name']}
#      ]

.directive("typeaheadSearchFilter", (Search, $filter) ->
  restrict: "E"
  templateUrl: "search/typeaheadFilterSearch.tpl.html"
  scope: {
    onSelect: '&'
    filter: '='
    ngModel: '='
    create: '&'
    clear: '='
    onFilterChange: '&'
  }

  link: (scope) ->
    scope.results = []

    if scope.filter && scope.filter[0]
      scope.activeFilter = scope.filter[0]

    scope.changeFilter = (filter) ->
      scope.activeFilter = filter
      scope.onFilterChange({filter: filter.name})

    # Results to show
    scope.getResultField = (model, newValue) ->
      if model
        resultField = for field in scope.activeFilter.fields
          if moment(model[field], "ISO").isValid() and field != 'number'
            resultField = $filter('date')(model[field], 'dd.MM.yyyy HH:mm')
          else
            resultField = model[field]
        if filterResults(newValue, resultField.join(' '))
          scope.results.push({'resultField': resultField.join(' '), 'model': model})

    filterResults = (newValue, resultText) ->
      unescaped_re = new RegExp('([-,])', 'g')
      resultText = resultText.replace(unescaped_re, ' ')
      newValue = newValue.replace(unescaped_re, '')

      re = new RegExp('([' + resultText + ']{' + newValue.length + ',})(?:,\s*)?', 'i')
      m = null
      if ((m = re.exec(newValue)) != null)
        if (m.index == re.lastIndex)
          re.lastIndex++
          return m

    # start searching
    search = (newValue)->
      Search.search(newValue, scope.activeFilter.name).then((results) ->
        scope.results = []
        for result in results.documents[scope.activeFilter.name].results
          scope.getResultField(result, newValue)
      , (e) ->
        console.error(e)
      )
    # Action for ngBlur - optional
    scope.save = ->
      if !scope.results?.length > 0
        scope.create()

    clear = ->
      scope.ngModel = ''

    scope.$watch 'clear', (newValue, oldValue) ->
      if newValue != oldValue
        clear()

    scope.$watch(->
      return scope.ngModel

    , (newValue) ->
      # just search this value
      if newValue? and newValue != "" and newValue.length > 3
        # TODO block search as long as the last one is not finished
        search(newValue)
      else
        # empty result
        scope.results = []
    )
)


.directive("typeaheadUibSearchFilter", (Search, $filter) ->
  restrict: "E"
  templateUrl: "search/typeaheadUibSearchFilter.tpl.html"
  scope: {
    onSelect: '&'
    filter: '='
    ngModel: '='
    create: '&'
    clear: '='
    onFilterChange: '&'
  }

  controller: ($scope, $filter) ->
    $scope.results = []

    if $scope.filter && $scope.filter[0]
      $scope.activeFilter = $scope.filter[0]

    $scope.changeFilter = (filter) ->
      clear()
      $scope.activeFilter = filter
      $scope.onFilterChange({filter: filter.name})

    # Results to show
    $scope.getResultField = (model, newValue) ->
      if model
        resultField = for field in $scope.activeFilter.fields
          if moment(model[field], "ISO").isValid() and field != 'number'
            resultField = $filter('date')(model[field], 'dd.MM.yyyy HH:mm')
          else
            resultField = model[field]
        resultField = _.pickBy(resultField)
        val = (_.values(resultField)).join(' ')
        return val

    $scope.getSearchResults = (val, limit) ->
      Search.search(val,$scope.activeFilter.name).then((results) ->
        #return $filter('limitTo')(results.documents[$scope.activeFilter.name].results, limit)
        return results.documents[$scope.activeFilter.name].results
      , (e) ->
        console.error(e)
      )

    clear = ->
      delete $scope.ngModel

    $scope.$watch 'clear', (newValue, oldValue) ->
      if newValue != oldValue
        clear()

    $scope.save = ->
      if !$scope.results?.length > 0
        $scope.create()
)


.directive("searchBar", (Search) ->
  restrict: "E"
  templateUrl: "search/searchBar.tpl.html"
  replace: true
  scope:
    show: '&'
    filter: '='

  controller: ($scope, $state, searchResult, Reducer, toastr) ->

    $scope.searchData = ''

    # start on enter
    $scope.startSearch = ->
      search($scope.searchData)
      $state.go 'main.searchResults'

    search = (newValue)->
      Search.search(newValue, $scope.filter).then((results) ->
        update(results)
        toastr.success "Here are the hits for '" + $scope.searchData + "' I have found.", "Search complete."
      , (e) ->
        toastr.warning 'OMFG. Something terribly went wrong. I feel so sorry.', 'Search complete.'
        console.error(e)
      )

    $scope.$watch 'filter', ->
      if $scope.searchData.length > 0
        search($scope.searchData)
        $state.go('main.searchResults')

    update = (results) ->
      searchResult.data = _.reduce(results.documents, (result, v, documentClass) ->
        reducer = new Reducer(v.search, ['user_name',
                                         'email', 'rank',
                                         'title', 'sex',
                                         'first_name',
                                         'last_name',
                                         'name',
                                         'number',
                                         'content',
                                         '_id',
                                         'deal_number',
                                         'phones',
                                         'mails',
                                         'addresses',
                                         'entry_date',
        ])

        reduced = reducer.reduce(v.results)
        if reduced?
          result[documentClass] =
            documents: _.map(reduced, (item) ->
              delete item.comments
              delete item.bundles
              delete item.contacts
              reducer.compact(item)
            )
            search: v.search

        return result
      , {})

    @show = ->
      $state.go('main.searchResults')

    $scope.$watch(->
      return $scope.searchData
    ,
      (newValue) ->
        # just search this value
        if newValue? and newValue != "" and newValue.length > 4
          # TODO block search as long as the last one is not finished
          search(newValue)

          $state.go 'main.searchResults'
        else
          # empty result
    )
)

.factory("Reducer", ->
  class Reducer
    constructor: (filter, preserve) ->

      # we need keys which should not vanish
      @preserve = if not _.isArray(preserve) then ([preserve] or []) else preserve

      if _.isFunction(filter)
        @filter = filter

      else if _.isArray(filter)
        # assume array of strings
        @filter = @contains(filter)

      else if _.isString(filter)
        @filter = @contains([filter])

        # TODO regexp

      else
        console.error("filter not implemented", filter)

    # default filter for strings and string arrays
    contains: (needles) ->
      needles = _.map(needles, (needle) ->
        return needle.toString().toLowerCase()
      )
      (string, key) ->
        if string?
          string = string.toString().toLowerCase()
        for needle in needles
          # assumes that  is a string
          if _.includes(string, needle)
            return true

        return false

    test: (value, key) ->
      # just reduce the value and test it
      if _.isObject(value) or _.isArray(value)
        # dive deeper
        reduced_value = @reduce(value)
        if not _.isEmpty(reduced_value)

          # preserve keys
          for key in @preserve
            if _.has(value, key)
              reduced_value[key] = value[key]

          return reduced_value

      else if @filter(value, key)
        return value

    reduce: (thing) ->
      # iterate over
      if _.isArray(thing) or _.isObject(thing)
        reduced = _.reduce(thing, (result, value, key) =>
          tested = @test(value, key)
          if tested?
            if _.isArray(result)
              result.push(tested)
            else
              result[key] = tested

          return result
        , if _.isArray(thing) then [] else {})

        return reduced

      else
        console.error("thing is no collection", thing)

    compact: (thing, root, acc) ->
      # compact all keys
      compacted = _.transform(thing, (result, value, key, obj) ->
        r_key = if root? then root + "." + key else key
        result[r_key] = value
#        if _.isArray(value) or _.isObject(value)
#          result[r_key] = value
#          for k, v in @compact(value, r_key, result)
#            result[r_key + "." + k] = v
#
#        else
#          result[r_key] = value

      , if acc? then acc else {})


  Reducer
)

.filter('highlight', ($sce) ->
  (text, phrases) ->
    if (typeof text == 'string' and phrases)
      for phrase in phrases
        text = text.replace(new RegExp('(' + phrase + ')', 'gi'),
          '<ddd>$1</ddd>')
      text = text.replace('<ddd>', '<mark class="highlight">')
      text = text.replace('</ddd>', '</mark>')

#     the following regex is not working:
#          text = text.replace(new RegExp('(' + phrase + ')', 'gi'),
#            '<mark class="highlight">$1</mark>')

      $sce.trustAsHtml(text)
)

.factory('Search', ($resource) ->
  search_resource = $resource("/search", {},
    search:
      method: "GET"
      isArray: false
  )

  class Search
    'use strict'

    search: (searchText, searchFilter) ->
      search_resource.search(
        search: searchText
        filter: searchFilter
      ).$promise

  new Search()
)

.directive("searchResults", ->
  scope:
    results: "="
    type: "="
    search: "="

  templateUrl: 'search/resultView.tpl.html'

  controller: ($state, $scope) ->
    $scope.openDocument = (type, _id) ->
      states =
        'User': ['main.user.edit', 'userId']
        'Customer': ['main.customer.edit.dashboard', 'customerId']
        'Company': ['main.company.edit.dashboard', 'companyId']
        'Document': ['main.stage.document.edit', 'documentId']
        'Lesson': ['main.stage.lesson.edit', 'lessonId']
        'Agenda': ['main.stage.agenda.edit', 'agendaId']
        'Sequence': ['main.stage.sequence.edit', 'sequenceId']
        'Module': ['main.stage.module.edit', 'moduleId']
        'Course': ['main.stage.course.edit', 'courseId']
        'Picks': ['main.stage.picks.edit', 'pickId']
        'Draft': ['main.stage.draft.edit', 'draftId']
        'Gig': ['main.allocation.gig.edit', 'gigId']
        'Measure': ['main.allocation.measure.edit.dashboard', 'measureId']
        'Deal': ['main.interaction.deal.edit', 'dealId']
        'Mandate': ['main.interaction.mandate.edit', 'mandateId']
        'Grant': ['main.interaction.grant.edit', 'grantId']
        'Template': ['main.interaction.template.edit', 'templateId']
        'Term': ['main.interaction.term.edit', 'termId']
        'Location': ['main.location.edit.dashboard', 'locationId']
        'Skill': ['main.skill.skill.edit', 'skillId']
        'PointType': ['main.skill.pointType.edit', 'pointTypeId']
        'RevenueAccount': ['main.admin.revenueAccount.edit', 'revenueAccountId']
        'CostUnit': ['main.admin.costUnit.edit', 'costUnitId']
        'Crowd': ['main.customer.import.edit', 'crowdId']

      stateParams = {}
      stateParams[states[type][1]] = _id
      $state.go(states[type][0], stateParams)
)

class searchResult
  constructor: ($scope, searchResult) ->
    $scope.results = searchResult


angular.module('sc.search')
.controller('searchResultController', ['$scope', 'searchResult', searchResult])