class scCompany
  constructor: ->
    return []

class companyConfig
  constructor: ($stateProvider) ->
    $stateProvider
    .state 'main.company',
      abstract: true
      url: '/company'
      templateUrl: 'company/company.tpl.html'
      data: {}

    .state 'main.company.edit',
      abstract: true
      url: '/edit/:companyId'
      templateUrl: 'company/edit.tpl.html'
      controller: 'companyEditController'
      data: {
        pageTitle: 'fg'
      }

    .state 'main.company.edit.dashboard',
      url: '/dashboard'
      templateUrl: 'company/dashboard.tpl.html'
      controller: 'companyEditDashboardController'
      data: {
        pageTitle: 'company View :: dashboard'
        pageName: 'Dashboard'
      }

    .state 'main.company.edit.names',
      url: '/names'
      templateUrl: 'company/name.tpl.html'
      controller: 'companyEditNamesController'
      data: {
        pageTitle: 'company View :: names'
        pageName: 'Edit names'
      }

    .state 'main.company.edit.addresses',
      url: '/addresses'
      templateUrl: 'company/address.tpl.html'
      controller: 'companyEditAddressesController'
      data: {
        pageTitle: 'company View :: addresses'
        pageName: 'Edit addresses'
      }

    .state 'main.company.edit.other',
      url: '/other'
      templateUrl: 'company/other.tpl.html'
      controller: 'companyEditOtherController'
      data: {
        pageTitle: 'company View :: other'
        pageName: 'Edit other'
      }

    .state 'main.company.edit.comments',
      url: '/comments'
      templateUrl: 'views/comment.tpl.html'
      controller: 'companyEditCommentsController'
      data: {
        pageTitle: 'company View :: comments'
        pageName: 'Comments'
      }

    .state 'main.company.edit.related',
      url: '/related'
      templateUrl: 'company/related.tpl.html'
      controller: 'companyEditRelatedController'
      data: {
        pageTitle: 'company View :: related'
        pageName: 'Edit related'
      }

    .state 'main.company.edit.hopes',
      url: '/hopes'
      templateUrl: 'company/hope.tpl.html'
      controller: 'companyEditHopesController'
      data: {
        pageTitle: 'company View :: hopes'
        pageName: 'Edit hopes'
      }

    .state 'main.company.edit.turnovers',
      url: '/turnovers'
      templateUrl: 'company/turnover.tpl.html'
      controller: 'companyTurnoverController'
      data: {
        pageTitle: 'company View :: turnovers'
        pageName: 'Turnovers'
      }


class companyPage
  constructor: () ->
    initialData = {}
    @setInitialData = (data) ->
      initialData = _.cloneDeep(data)

    @getInitialData = ->
      return initialData

# controller to create a new company
class companyCreate
  constructor: ($scope, $rootScope, $state, Company, toastr, $translate, Control) ->

# Wizard config
    $scope.step = 1
    $scope.wizard =
      show: (number) ->
        $scope.step = number
      next: ->
        $scope.step++
      prev: ->
        $scope.step--

    $scope.topic = {}

    $scope.tags = []
    tag_list = []
    Control.companyTagService.one().get().then (response) ->
      tag_list = response.tags

    $scope.refreshTags = (word) ->
      if word
        $scope.tags = []
        for tag in tag_list
          if _.startsWith(tag.toLowerCase(), word.toLowerCase())
            $scope.tags.push(tag)
      return $scope.tags

    $scope.types =
      phone: Company.phoneTypes
      mail: Company.mailTypes
      address: Company.addressTypes

    $scope.options = Company.summernoteOptions

    $scope.clear = false

    $scope.data =
      addresses: []
      mails: []
      phones: []
      comments: []
      contacts: []
      supervisors: []
      locations: []

    $scope.addSupervisor = (model) ->
      model.user_id = model._id
      model.topic = $scope.topic.supervisor
      $scope.data.supervisors.push(model)
      $scope.clear = !$scope.clear
      $scope.topic = {}


    $scope.addContact = (model) ->
      model.customer_id = model._id
      model.topic = $scope.topic.contact
      $scope.data.contacts.push(model)
      $scope.clear = !$scope.clear
      $scope.topic = {}

    $scope.addLocation = (model) ->
      model.location_id = model._id
      $scope.data.locations.push(model)
      $scope.clear = !$scope.clear

    $scope.createCompany = ->
      if $scope.data.commentTitle || $scope.data.commentBody
        $scope.data.comments.push({
          author: $rootScope.state.user.user_name
          body: $scope.data.commentBody
          title: $scope.data.commentTitle
        })

      data = Company.companyData($scope.data)

      Company.service.post(data).then (response) ->
        $translate('CreateMsg', {name: response.name}).then (text) ->
          toastr.success text
        #Jump to company dashboard
        $state.go 'main.company.edit.dashboard', {'companyId': response._id}

      , (response) ->
        console.log 'error', data
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.errors = response.data.errors

# controller to get a company and bind it to the scope
class companyEdit
  constructor: (
    $scope, $rootScope, $http, $state, $stateParams, $translate, toastr, Company, Mailto, companyPageService) ->
    $scope.data =
      deals: []

    $scope.errors = []

    getMailHref = (data) ->
      options =
        body: 'Sehr geehrte Damen und Herren,'

      $scope.href = Mailto.url(data.mails[0].mail_address, options) if data.mails.length

    getDeals = ->
# get the detail of the deals for the company
      companyDealPromise = Company.service.one($stateParams.companyId).one('deals').get()
      companyDealPromise.then (dealData) ->
        $scope.data.deals = dealData
      , (response) ->
        console.log 'error', response
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.errors = response.data.errors

    getMandates = ->
# get the detail of the mandates for the customer
      companyMandatePromise = Company.service.one($stateParams.companyId).one('mandates').get()
      companyMandatePromise.then (mandateData) ->
        $scope.data.mandates = mandateData
      , (response) ->
        console.log 'error', response
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.errors = response.data.errors

    getCurrentGuesses = ->
      for hope in $scope.data.hopes
        hope.currentGuess = _.cloneDeep _.last hope.guesses # crazy coffee style function call
        hope.generator =
          start: new Date(moment().add(1, 'month').startOf('month'))
          value: 0.0
          months: 1

    if $stateParams.companyId
      companyPromise = Company.service.one($stateParams.companyId).get()
      companyPromise.then (data) ->
        $scope.data = data
        getMailHref(data)
        getDeals()
        getMandates()
        getCurrentGuesses()
        companyPageService.setInitialData(data)

    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
      if (angular.isDefined(toState.data.pageTitle))
        $scope.pageName = toState.data.pageName

    $scope.saveCompany = ->
      data = Company.companyData($scope.data)
      Company.service.one($scope.data._id).customPUT(data).then (response) ->
        $scope.data = response
        $translate('EditMsg', {name: response.name}).then (text) ->
          toastr.success text
        $scope.data.errors = []
#If put fails show error toastr and double bind backend error response
      , (response)  ->
        console.log 'error', data
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.data.errors = response.data.errors

class companyEditDashboard
  constructor: ($scope, $state, $stateParams) ->

# controller to edit names, emails and phones
class companyEditNames
  constructor: ($scope, $state, $stateParams, Company, toastr, $translate) ->
    $scope.types =
      phone: Company.phoneTypes
      mail: Company.mailTypes

# controller to edit and add addresses
class companyEditAddresses
  constructor: ($scope, $state, $stateParams, Company, toastr, $translate) ->

    $scope.types =
      address: Company.addressTypes

# controller to edit stuff like adStop, tags and alerts
class companyEditOther
  constructor: ($scope, $state, $stateParams, Company, toastr, $translate, Control) ->
    tag_list = []
    $scope.tags = []
    Control.companyTagService.one().get().then (response) ->
      tag_list = response.tags

    $scope.refreshTags = (word) ->
      if word
        $scope.tags = []
        for tag in tag_list
          if _.startsWith(tag.toLowerCase(), word.toLowerCase())
            $scope.tags.push(tag)
      return $scope.tags

# controller to edit and add comments
class companyEditComments
  constructor: ($scope, $rootScope, $state, toastr, Company, $translate) ->
    $scope.options = Company.summernoteOptions

    $scope.addComment = ->
      if $scope.data.commentTitle && $scope.data.commentBody
        $scope.data.comments.push({
          author: $rootScope.state.user.user_name
          body: $scope.data.commentBody
          title: $scope.data.commentTitle
        })

        title = $scope.data.commentTitle
        data = Company.companyData($scope.data)
        Company.service.one($scope.data._id).customPUT(data).then (response) ->
          delete $scope.data.commentBody
          delete $scope.data.commentTitle
          $scope.data = response
          toastr.success title + ' was added.', 'Save successful.'
          $scope.data.errors = []
          #If put fails show error toastr and double bind backend error response
        , (response)  ->
          console.log 'error', data
          $translate("ErrorMsg").then (text) ->
            toastr.error text
          $scope.data.errors = response.data.errors
      else
        toastr.error $translate.instant('Please fill out comment title and comment body')

    $scope.deleteComment = (oldItem) ->
      data = Company.companyData($scope.data)
      company.one($scope.data._id).customPUT(data).then (response) ->
        $scope.data = response

        toastr.success oldItem.title + ' was deleted.', 'Delete successful.'
#If put fails show error toastr and double bind backend error response
      , (response)  ->
        console.log 'error', data
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.data.errors = response.data.errors

# controller to add and find related locations, contacts and supervisors
class companyEditRelated
  constructor: ($scope, $state, $stateParams, Company, toastr, $translate) ->
    $scope.topic = {}

    $scope.clear = false

    $scope.addSupervisor = (model) ->
      model.user_id = model._id
      model.topic = $scope.topic.supervisor
      $scope.data.supervisors.push(model)
      $scope.topic = {}
      $scope.clear = !$scope.clear

    $scope.addContact = (model) ->
      model.customer_id = model._id
      model.topic = $scope.topic.contact
      $scope.data.contacts.push(model)
      $scope.topic = {}
      $scope.clear = !$scope.clear

    $scope.addLocation = (model) ->
      model.location_id = model._id
      $scope.data.locations.push(model)
      $scope.topic = {}
      $scope.clear = !$scope.clear

class companyEditHopes
  constructor: ($scope, $state, $stateParams, Company, toastr, $translate, $filter, $rootScope, companyPageService) ->
    $scope.format = "dd.MM.yyyy"

    $scope.saveCompany = ->
      data = Company.companyData($scope.data)
      Company.service.one($scope.data._id).customPUT(data).then (response) ->
        companyPageService.setInitialData(response)
        $translate('EditMsg', {name: response.name}).then (text) ->
          toastr.success text
        $scope.data.errors = []
      #If put fails show error toastr and double bind backend error response
      , (response) ->
        $scope.data = companyPageService.getInitialData()
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.data.errors = response.data.errors

    $scope.newHope = ->
      $scope.data.hopes.push({
        name: 'New Hope'
        hoper:
          hoper_id: $rootScope.state.user._id
          full_name: $rootScope.state.user.full_name
        generator:
          start: new Date(moment().add(1, 'month').startOf('month'))
          value: 0.0
          months: 1
        currentGuess:
          turnovers: []
          status: $scope.hopeStatus[0]
          contribution_margin: 0.5
        guesses: []
      })

    $scope.hopeStatus = Company.hopeStatus

    $scope.addTurnover = (currentGuess) ->
      currentGuess.turnover['currency'] = 'EUR'
      currentGuess.turnovers.push(_.cloneDeep(currentGuess.turnover))

    $scope.getTimespan = (turnovers) ->
      sorted_turnovers = _.sortBy(turnovers, 'due_date')
      first_date = $filter('naiveToLocal')(_.head(sorted_turnovers).due_date)
      last_date = $filter('naiveToLocal')(_.last(sorted_turnovers).due_date)
      return "#{first_date} - #{last_date}"

    $scope.guess = (hope) ->
      if hope && hope.currentGuess && hope.currentGuess.turnovers
        hope.currentGuess.guess_date = new Date()
        hope.guesses.push(_.cloneDeep(hope.currentGuess))


    $scope.getTotals = (turnovers) ->
      _.sumBy(turnovers, 'value')

    $scope.deleteTurnover = (currentGuess, turnover) ->
      _.remove(currentGuess.turnovers, turnover)

    $scope.getGuessAge = (guessDate) ->
      if guessDate then moment(new Date(guessDate)).fromNow() else moment().fromNow()

    $scope.generateTurnover = (generator, currentGuess) ->
      if generator.value > 0 && generator.months && generator.start &&
        currentGuess.revenue_account && currentGuess.turnovers
          valueMonth = generator.value / Math.abs(generator.months)
          currentGuess['turnovers'] = []
          for month in [0..generator.months - 1]
            currentGuess.turnovers.push({
              due_date: moment(generator.start).add(month, 'month').toDate()
              value: valueMonth
              currency: 'EUR'
            })
        else
          $translate("ErrorMsg").then (text) ->
            toastr.error text

    $scope.newGuess = (hope) ->
      hope.guesses.push(hope.currentGuess)

    $scope.selectRevenueAccount = (model) ->
      $scope.currentGuess.revenue_account = model


class companyTurnover
  constructor: ($scope, $state, $stateParams, Company, toastr, $translate, Control) ->

    $scope.lineOptions =
      scales:
        xAxes: [{
          stacked: true
        }],
        yAxes: [
          {
            id: "turnover-y"
            stacked: true
            display: true
            ticks:
              min: 0
              beginAtZero: true
            position: "left"
          },
          {
            id: "debit-y"
            position: "right"
            display: false
            ticks:
              min: 0
              beginAtZero: true
              max: 50000
          }
        ]

    $scope.lineData = {
      labels: []
      datasets: []
    }

    $scope.dateRangePicker = {
      dateRange:
        startDate: moment().add(0, 'months').startOf('month')
        endDate: moment().add(9, 'months').endOf('month')
      options:
        locale:
          format: 'DD.MM.YYYY'
    }


    lineColors =
      raised:
        id: 'turnover-y'
        type: 'line'
        bgC: "rgba(224,224,224,0.5)"
        bC: "rgba(224,224,224,0.8)"
        pBC: "rgba(224,224,224,1)"
      vague:
        id: 'turnover-y'
        fill: false
        type: 'line'
        bgC: "rgba(255,204,153,0.5)"
        bC: "rgba(255,204,153,0.8)"
        pBC: "rgba(255,204,153,1)"
      confident:
        id: 'turnover-y'
        fill: false
        type: 'line'
        bgC: "rgba(255,178,102,0.5)"
        bC: "rgba(255,178,102,0.8)"
        pBC: "rgba(255,178,102,1)"
      justified:
        id: 'turnover-y'
        fill: false
        type: 'line'
        bgC: "rgba(255,153,51,0.5)"
        bC: "rgba(255,153,51,0.8)"
        pBC: "rgba(255,153,51,1)"
      fulfilled:
        id: 'turnover-y'
        fill: false
        type: 'line'
        bgC: "rgba(0,102,204,0.5)"
        bC: "rgba(0,102,204,0.8)"
        pBC: "rgba(0,102,204,1)"
      lost:
        id: 'turnover-y'
        fill: false
        type: 'line'
        bgC: "rgba(51,0,0,0.5)"
        bC: "rgba(51,0,0,0.8)"
        pBC: "rgba(51,0,0,1)"
      provisional:
        id: 'turnover-y'
        fill: true
        type: 'bar'
        bgC: "rgba(102,255,102,0.5)"
        bC: "rgba(102,255,102,0.8)"
        pBC: "rgba(102,255,102,1)"
      debited:
        id: 'turnover-y'
        fill: true
        type: 'bar'
        bgC: "rgba(0,204,0,0.5)"
        bC: "rgba(0,204,0,0.8)"
        pBC: "rgba(0,204,0,1)"

    $scope.query = ->
      runQuery()

    runQuery = ->
      $translate('QueryStart').then (text) ->
        toastr.info text
      #prepare start and end date
      data = {
        company_id: $stateParams.companyId
        start_date: $scope.dateRangePicker.dateRange.startDate
        end_date: $scope.dateRangePicker.dateRange.endDate
      }
      queryPromise = Control.turnoverService.post(data)
      queryPromise.then (response) ->
        # reset datasets
        $scope.lineData.datasets.length = 0

        $translate('QuerySuccess').then (text) ->
          $scope.lineData.labels = response.labels

          for dataset in response.datasets
            dataset['yAxisID'] = lineColors[dataset.label].id
            dataset['type'] = lineColors[dataset.label].type
            dataset['fill'] = lineColors[dataset.label].fill
            dataset['backgroundColor'] = lineColors[dataset.label].bgC
            dataset['borderColor'] = lineColors[dataset.label].bC
            dataset['pointBorderColor'] = lineColors[dataset.label].pBC
            dataset['pointBackgroundColor'] = "#fff"
            dataset['pointBorderWidth'] = 1
            dataset['pointHoverRadius'] = 5
            dataset['pointHoverBackgroundColor'] = "rgba(255,205,86,1)"
            dataset['pointHoverBorderColor'] = "rgba(255,205,86,1)"
            dataset['pointHoverBorderWidth'] = 2
          $scope.lineData.datasets = response.datasets
          toastr.success text
      , (response) ->
        console.log(response)
        $translate('QueryError').then (text) ->
          toastr.warning text
    # initially run the query
    runQuery()


angular.module('sc.company', new scCompany())
.config(['$stateProvider', companyConfig])
.service('companyPageService', [companyPage])
.controller('companyCreateController', ['$scope', '$rootScope', '$state', 'Company', 'toastr', '$translate', 'Control', companyCreate])
.controller('companyEditController', ['$scope', '$rootScope', '$http', '$state', '$stateParams', '$translate', 'toastr', 'Company', 'Mailto', 'companyPageService', companyEdit])
.controller('companyEditDashboardController', ['$scope', '$state', '$stateParams', companyEditDashboard])
.controller('companyEditNamesController', ['$scope', '$state', '$stateParams', 'Company', 'toastr', '$translate', companyEditNames])
.controller('companyEditAddressesController', ['$scope', '$state', '$stateParams', 'Company', 'toastr', '$translate', companyEditAddresses])
.controller('companyEditOtherController', ['$scope', '$state', '$stateParams', 'Company', 'toastr', '$translate', 'Control', companyEditOther])
.controller('companyEditCommentsController', ['$scope', '$rootScope', '$state', 'toastr', 'Company', '$translate', companyEditComments])
.controller('companyEditRelatedController', ['$scope', '$state', '$stateParams', 'Company', 'toastr', '$translate', companyEditRelated])
.controller('companyEditHopesController', ['$scope', '$state', '$stateParams', 'Company', 'toastr', '$translate', '$filter', '$rootScope', 'companyPageService', companyEditHopes])
.controller('companyTurnoverController', ['$scope', '$state', '$stateParams', 'Company', 'toastr', '$translate', 'Control', companyTurnover])