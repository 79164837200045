class scCustomer
  constructor: ->
    return ['ui.router', 'toastr', 'pascalprecht.translate', 'ngFileUpload', 'customer.import']

class customerConfig
  constructor: ($stateProvider) ->
    $stateProvider
    .state 'main.customer',
      abstract: true
      url: '/customer'
      templateUrl: 'customer/customer.tpl.html'
      data: {}

    .state 'main.customer.edit',
      abstract: true
      url: '/edit/:customerId'
      templateUrl: 'customer/edit.tpl.html'
      controller: 'customerEditController'
      data: {
        pageTitle: ''
      }

    .state 'main.customer.edit.dashboard',
      url: '/dashboard'
      templateUrl: 'customer/dashboard.tpl.html'
      controller: 'customerEditDashboardController'
      data: {
        pageTitle: 'Customer View :: dashboard'
        pageName: 'Dashboard'
      }

    .state 'main.customer.edit.names',
      url: '/names'
      templateUrl: 'customer/name.tpl.html'
      controller: 'customerEditNamesController'
      data: {
        pageTitle: 'Customer View :: names'
        pageName: 'Edit names'
      }

    .state 'main.customer.edit.addresses',
      url: '/addresses'
      templateUrl: 'customer/address.tpl.html'
      controller: 'customerEditAddressesController'
      data: {
        pageTitle: 'Customer View :: addresses'
        pageName: 'Edit addresses'
      }

    .state 'main.customer.edit.other',
      url: '/other'
      templateUrl: 'customer/other.tpl.html'
      controller: 'customerEditOtherController'
      data: {
        pageTitle: 'Customer View :: other'
        pageName: 'Edit other'
      }
      resolve:{
        'picksData': (Picks) ->
          return Picks.picksPromise
      }

    .state 'main.customer.edit.comments',
      url: '/comments'
      templateUrl: 'views/comment.tpl.html'
      controller: 'customerEditCommentsController'
      data: {
        pageTitle: 'Customer View :: comments'
        pageName: 'Comments'
      }

    .state 'main.customer.edit.schedule',
      url: '/schedule'
      templateUrl: 'customer/schedule.tpl.html'
      controller: 'customerEditScheduleController'
      data: {
        pageTitle: 'Customer View :: schedule'
        pageName: 'Edit schedule'
      }

    .state 'main.customer.edit.busy',
      url: '/busy'
      templateUrl: 'customer/busy.tpl.html'
      controller: 'customerEditBusyController'
      data: {
        pageTitle: 'Customer View :: busy'
        pageName: 'Edit busy'
      }

    .state 'main.customer.available',
      url: '/available'
      templateUrl: 'customer/available.tpl.html'
      controller: 'customerAvailableController'
      date: {
        pageTitle: 'Customer View :: available'
        pageName: 'Customer available'
      }

class customerEdit
  constructor: ($scope, $rootScope, $filter, $http, $state, $stateParams, toastr, $translate, Customer, Mailto) ->
    $scope.data =
      deals: []
      tags: []

    $scope.sendCredentials = (credential_type) ->
      payload =
        action: 'mail_credentials'
        args: [credential_type]
      Customer.service.one($scope.data._id).one('action').customPOST(payload).then (response) ->
        toastr.success 'Action successfully executed'
      , (response) ->
        toastr.error(response.data.message)

    $scope.getDefaultAddress = ->
      $filter('default_address')($scope.data)

    getMailHref = (data) ->
      options =
        body: $filter('salutationLetter')(data) + ','

      $scope.href = Mailto.url(data.mails[0].mail_address, options) if data.mails.length


    getDeals = ->
      # get the detail of the deals for the customer
      customerDealPromise = Customer.service.one($stateParams.customerId).one('deals').get()
      customerDealPromise.then (dealData) ->
        $scope.data.deals = dealData
      , (response) ->
        console.log 'error', response
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.errors = response.data.errors

    getMandates = ->
      # get the detail of the mandates for the customer
      customerMandatePromise = Customer.service.one($stateParams.customerId).one('mandates').get()
      customerMandatePromise.then (mandateData) ->
        $scope.data.mandates = mandateData
      , (response) ->
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.errors = response.data.errors

    getGigs = ->
      # get the detail of the gigs for the customer
      customerGigPromise = Customer.service.one($stateParams.customerId).one('gigs').get()
      customerGigPromise.then (gigData) ->
        $scope.data.gigs = gigData
      , (response) ->
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.errors = response.data.errors

    getMemberships = ->
      # get the detail of the memberships for the customer
      customerMembershipPromise = Customer.service.one($stateParams.customerId).one('memberships').get()
      customerMembershipPromise.then (response) ->
        $scope.data.memberships = response
      , (response) ->
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.errors = response.data.errors


    if $stateParams.customerId
      customerPromise = Customer.service.one($stateParams.customerId).get()
      customerPromise.then (data) ->
        $scope.data = data
        getMailHref(data)
        getDeals()
        getMandates()
        getGigs()
        getMemberships()

    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
      if (angular.isDefined(toState.data.pageTitle))
        $scope.pageName = toState.data.pageName

class customerEditDashboard
  constructor: ($scope, $sce, $state, $stateParams) ->

class customerEditNames
  constructor: ($scope, $state, $stateParams, $filter, Customer, toastr, Company, $translate) ->

    $scope.titles = Customer.titles

    $scope.types =
      phone: Customer.phoneTypes
      mail: Customer.mailTypes

    $scope.format = "dd.MM.yyyy"

    $scope.dateOptions =
      formatYear: 'yy'
      maxDate: new Date(2020, 5, 22)
      minDate: new Date(1900,12,31)
      startingDay: 1

class customerEditAddresses
  constructor: ($scope, $state, $filter, $stateParams, Customer, toastr, $translate) ->

    $scope.types =
      address: Customer.addressTypes

    $scope.contact =
      addresses: []
      filters: [
        {name: "Customer", fields: ["first_name", "last_name", "number"]}
        {name: "Company", fields: ["name", "number"]}
      ]

    $scope.address = {
      address_type: ''
    }

class customerEditOther
  constructor: (
    $scope, $state, $filter, $stateParams, Customer, toastr, $translate, Control, Error, $resource, Picks) ->

    $scope.tags = []
    tag_list = []
    $scope.types =
      sources: Customer.sources
      credential: Customer.credentialTypes
      key_values_picks: Picks.getPicksByName('key_values')?.pick_values


    $scope.checkCredentials =  ->
      valid = true
      for c in $scope.data.credentials
        if !c.credential_type || ! c.user_name
          valid = false
      return valid

    Control.customerTagService.one().get().then (response) ->
      tag_list = response.tags.sort()

    $scope.refreshTags = (word) ->
      if word
        $scope.tags = []
        for tag in tag_list
          if _.startsWith(tag.toLowerCase(), word.toLowerCase())
            $scope.tags.push(tag)
      return $scope.tags


class customerEditSchedule
  constructor: ($scope, $stateParams, Customer, $state, toastr, $translate, Measure, $uibModal) ->
    getMeasures = ->
      promise = Customer.measureService.one($stateParams.customerId).get()
      promise.then (data) ->
        $scope.measures = data.measures
        $scope.left_gigs = data.left_gigs
    getMeasures()

    $scope.getModules = (measure) ->
      modules = []
      for course in measure.courses
        for sequence in course.sequences
          for module in sequence.modules
            modules.push(module)
      return modules

    prepareRebookModel = (_gig) ->
      _model =
        dealId: _.filter(_gig.attendees, {'attendee_id': $scope.data._id})[0].deal_id
        measureId: _.filter(_gig.attendees, {'attendee_id': $scope.data._id})[0].measure_id

      _model['modules'] =
        name: _gig.name
        agendas: [{
          gig: _gig
        }]

      return _model

    $scope.showReRebook = (_gig) ->
      _model = prepareRebookModel(_gig)
      $uibModal.open({
        templateUrl: 'customer/rebook.tpl.html'
        controller: 'customerRebookModalController'
        windowClass: "hmodal-success"
        size: 'lg'
        resolve: {
          model: ->
            return _model['modules']
          customerModel: ->
            return $scope.data
          dealId: ->
            return _model['dealId']
          measureId: ->
            return _model['measureId']
        }
      })

    $scope.showRebook = (module, deal_id, measure_id)  ->
      $uibModal.open({
        templateUrl: 'customer/rebook.tpl.html'
        controller: 'customerRebookModalController'
        windowClass: "hmodal-success"
        size: 'lg'
        resolve: {
          model: ->
            return module
          customerModel: ->
            return $scope.data
          dealId: ->
            return deal_id
          measureId: ->
            return measure_id
        }
      })


class customerEditComments
  constructor: ($scope, $sce, $filter, $rootScope, Customer, $state, toastr, $translate, Error) ->
    $scope.options = Customer.summernoteOptions

    $scope.addComment = ->
      if $scope.data.commentTitle && $scope.data.commentBody
        $scope.data.comments.push({
          author: $rootScope.state.user.user_name
          body: $scope.data.commentBody
          title: $scope.data.commentTitle
        })

        title = $scope.data.commentTitle
        $scope.data.put().then (response) ->
          delete $scope.data.commentBody
          delete $scope.data.commentTitle
          $scope.data = response
          toastr.success title + ' was added.', 'Save successful.'
        , (response) ->
          #TODO: Move to Restangular config after merge of etag feature
          if(response.status == 500)
            toastr.error $translate.instant('Server error'), response.statusText
          else
            Error.getErrorMessage(response.data.errors, $scope.data, 'customer')
            $scope.data.comments = _.dropRight($scope.data.comments)
      else
        toastr.error $translate.instant('Please fill out comment title and comment body')

    $scope.editComment = (comment) ->
      oldItem = $scope.data.comments.splice($scope.data.comments.indexOf(comment), 1)[0]
      $scope.data.commentTitle = oldItem.title
      $scope.data.commentBody = oldItem.body

    $scope.deleteComment = (oldItem) ->
      data = customerData.all($scope.data)
      $scope.data.put().then (response) ->
        $scope.data = response
        toastr.success oldItem.title + ' was deleted.', 'Delete successful.'


class customerEditBusy
  constructor: ($scope, $compile, $state, $stateParams, Customer, toastr, $translate, $filter) ->
    $scope.uiConfig = {
      calendar: {
        lang: 'de'
        header: {
          left: 'month basicWeek agendaDay',
          center: 'title',
          right: 'today prev,next'
        },
        eventClick: (calEvent, jsEvent, view) ->
          $scope.eventClicked(calEvent, jsEvent, view)
        eventDrop: (calEvent, delta) ->
          $scope.eventDropped(calEvent, delta)
        eventResize: $scope.alertOnResize
        eventRender: (event, element, view) ->
          $scope.eventRender(event, element, view)
      }
    }

    $scope.saveCustomer = ->
      $scope.data.busy = matchBusy()
      $scope.data.put().then (response) ->
        $scope.data = response
        $translate('EditMsg', {name: $filter('fullName')(response)}).then (text) ->
          toastr.success text
        $scope.data.errors = []
        #If put fails show error toastr and double bind backend error response
      , (response)  ->
        $translate("ErrorMsg").then (text) ->
          toastr.error text
        $scope.data.errors = response.data.errors

    other = []
    custom = []

    $scope.event =
      title: ''
      start: new Date()
      end: new Date()

    $scope.eventRender = (event, element, view) ->
      element.attr({'tooltip': event.title, 'tooltip-append-to-body': true})
      $compile(element)($scope)

    $scope.eventDropped = (date, delta) ->
      $scope.saveCustomer()

    $scope.eventClicked = (date, jsEvent, view) ->
      if date.editable
        $scope.event.title = date.title
        $scope.event.start = new Date(date.start)
        $scope.event.end = new Date(date.end)
        _.remove custom, (n) ->
          n._id == date._id

    $scope.addEvent = ->
      custom.push({
        'title': $scope.event.title
        'start': $scope.event.start
        'end': $scope.event.end
        'stick': true
        'color': "#34495e"
        'editable': true
      })
      $scope.saveCustomer()
      $scope.event =
        title: ''
        start: new Date()
        end: new Date()

    $scope.clearEvent = ->
      $scope.saveCustomer()
      $scope.event =
        title: ''
        start: new Date()
        end: new Date()

    matchEvents = (busy, list) ->
      list.push(
        {
          'title': busy.reason
          'start': new Date(busy.begin)
          'end': new Date(busy.end)
          'color': if busy.busy_type == "custom" then "#34495e" else "orange"
          'stick': true
          'editable': if busy.busy_type == "custom" then true else false
        }
      )

    $scope.eventSources = [$scope.events]
    if $stateParams.customerId?.length
      customerPromise = Customer.service.one($stateParams.customerId).get()
      customerPromise.then (data) ->
        # Get custom events
        customEvents = _.filter(data.busy, {busy_type: "custom"})
        matchEvents item, custom for item in customEvents
        # Get other events
        otherEvents = _.uniq(([]).concat(
          _.filter(data.busy, {busy_type: "attendee"}),
          _.filter(data.busy, {busy_type: "trainer"})
        ))
        matchEvents item, other for item in otherEvents

    $scope.eventSources = [other, custom]

    matchBusy = ->
      busy = []
      busy.push({
        'reason': item.title
        'begin': item.start
        'end': item.end
        'busy_type': 'custom'
      }) for item in custom
      busy


class customerRebookModal
  constructor: ($scope, $rootScope, customerModel, model, Customer, toastr, Gig, Measure, Match, $translate, $filter,
    measureId, dealId) ->
    $scope.customer = customerModel
    $scope.module = model

    $scope.searchMatchingGig = (gig) ->
      data =
        begin: new Date()
        name: gig.name

      gigPromise = Match.gigMatchService.post(data)
      gigPromise.then (data) ->
        $scope.gigs = data.gigs
        $translate('GigSearchSuccess', {'length': data.gigs.length}).then (text) ->
          toastr.success text
      , (data) ->
        $translate("ErrorMsg").then (text) ->
          toastr.error text

    $scope.unsubscribe = (gig) ->
      _.remove(gig.attendees, {
        'attendee_id': customerModel._id
      })
      Gig.service.one(gig._id).customPUT(gig).then (response) ->
        gig.unsubscribeText = "text-danger"
        $translate('UnsubscribeMsg',
          {
            gigName: response.name,
            customerName: $filter('fullName')(customerModel)
          }
        ).then (text) ->
          toastr.success text


    $scope.subscribe = (gig) ->
      gig.attendees.push({
        attendee_id: customerModel._id
        status: 'allowed'
        deal_id: dealId
        measure_id: measureId
      })
      Gig.service.one(gig._id).customPUT(gig).then (response) ->
        gig.subscribeText = "text-success"
        $translate('SubscribeMsg',
          {
            gigName: response.name,
            customerName: $filter('fullName')(customerModel)
          }
        ).then (text) ->
          toastr.success text


class CustomerAvailable
  constructor: ($scope, Customer, toastr, $translate) ->

    $scope.format = "dd.MM.yyyy"

    $scope.dateOptions =
      formatYear: 'yy'
      maxDate: new Date(2099, 12, 31)
      minDate: new Date(1900,12,31)
      startingDay: 1

    $scope.payload =
      skills: []
      begin: moment().add(1,'week').startOf('week')
      end: moment().add(1, 'week').endOf('week')

    $scope.wipe = ->
      $scope.payload =
        skills: []
        begin: moment().add(1,'week').startOf('week')
        end: moment().add(1, 'week').endOf('week')
      $scope.customers = []
      $scope.skill_model = ''

    $scope.selectSkill = (model) ->
      $scope.skill_model = ''
      $scope.payload.skills.push({
        'skill_id': model._id
        'name': model.name
        'status': 'achieved'
      })

    $scope.search = ->
      $translate('QueryStart').then (text) ->
        toastr.info text
      Customer.service.one('availability').customPOST($scope.payload).then (response) ->
        $scope.customers = response.customers
        $translate('QuerySuccess').then (text) ->
          toastr.success text
      , (response) ->
        error = _.find(response.data.errors, { 'name': 'skills' })
        if error
          $translate('MinimumLength', { 'field': 'Skill' }).then (text) ->
            toastr.warning text
        else
          $translate('QueryError').then (text) ->
            toastr.warning text


angular.module('sc.customer', new scCustomer())
.config(['$stateProvider', customerConfig])
.controller('customerEditController', ['$scope', '$rootScope', '$filter', '$http', '$state', '$stateParams', 'toastr', '$translate', 'Customer', 'Mailto', customerEdit])
.controller('customerEditDashboardController', ['$scope', '$sce', '$state', '$stateParams', customerEditDashboard])
.controller('customerEditNamesController', ['$scope', '$state', '$stateParams', '$filter', 'Customer', 'toastr', 'Company', '$translate', customerEditNames])
.controller('customerEditAddressesController', ['$scope', '$state', '$filter', '$stateParams', 'Customer', 'toastr', '$translate', customerEditAddresses])
.controller('customerEditOtherController', ['$scope', '$state', '$filter', '$stateParams', 'Customer', 'toastr', '$translate', 'Control', 'Error', '$resource', 'Picks', customerEditOther])
.controller('customerEditScheduleController', ['$scope', '$stateParams', 'Customer', '$state', 'toastr', '$translate', 'Measure', '$uibModal', customerEditSchedule])
.controller('customerEditCommentsController', ['$scope', '$sce', '$filter', '$rootScope', 'Customer', '$state', 'toastr', '$translate', 'Error', customerEditComments])
.controller('customerEditBusyController', ['$scope', '$compile', '$state', '$stateParams', 'Customer', 'toastr', '$translate', '$filter', customerEditBusy])
.controller('customerRebookModalController', ['$scope', '$rootScope', 'customerModel', 'model', 'Customer', 'toastr', 'Gig', 'Measure', 'Match', '$translate', '$filter', 'measureId', 'dealId', customerRebookModal])
.controller('customerAvailableController', ['$scope', 'Customer', 'toastr', '$translate', CustomerAvailable])